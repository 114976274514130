import React from 'react';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Agenda from './pages/Agenda';
import Footer from './components/Footer';
import GuideAdmin from './pages/GuideAdmin';
import GuideProfile from './pages/GuideProfile';
//import Error from './Page/Error';
import Navbar from './components/Navbar';

import ScrollTop from './ScrollTop';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/boek-een-bosbad" element={<Agenda />} />
        <Route path="/gids/:guideId" element={<GuideProfile />} />
        <Route path="/admin/:authToken" element={<GuideAdmin />} />
        {/*<Route path="*" element={<Error />} />*/}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
